import React, { useRef, useState } from 'react';
import Banner from '../components/common/Banner';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTag } from '@fortawesome/free-solid-svg-icons';
import stars from "../assets/images/contact/stars.png";
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    const form = useRef();
    const fnameRef = useRef();
    const lnameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const subjectRef = useRef();
    const messageRef = useRef();

    const [errorMessage, setErrorMessage] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        if (validation()) {
            emailjs
                .sendForm('service_lej83j3', 'template_h6114qh', form.current, {
                    publicKey: 'yDJxyYfAfEEPe_lLm',
                })
                .then(
                    (res) => {
                        console.log('SUCCESS!', res);
                        setSuccessMessage("The message was successfully sent!");
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                        setErrorMessage(["Failed to send message. Please try again later."]);
                    },
                );
        }
    };

    function validation() {
        let isValid = true;
        setErrorMessage([]);

        if (fnameRef.current.value.trim() === "") {
            setErrorMessage(prevErrors => [...prevErrors, t(`contact.formValidation.fname`)]);
            isValid = false;
        }
        if (lnameRef.current.value.trim() === "") {
            setErrorMessage(prevErrors => [...prevErrors, t(`contact.formValidation.lname`)]);
            isValid = false;
        }
        if (phoneRef.current.value.trim() === "") {
            setErrorMessage(prevErrors => [...prevErrors, t(`contact.formValidation.phone`)]);
            isValid = false;
        }
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(emailRef.current.value.trim())) {
            setErrorMessage(prevErrors => [...prevErrors, t(`contact.formValidation.email`)]);
            isValid = false;
        }
        if (subjectRef.current.value.trim().length < 10) {
            setErrorMessage(prevErrors => [...prevErrors, t(`contact.formValidation.subject`)]);
            isValid = false;
        }
        if (messageRef.current.value.trim().length < 20) {
            setErrorMessage(prevErrors => [...prevErrors, t(`contact.formValidation.details`)]);
            isValid = false;
        }

        return isValid;
    }

    return (
        <>
            <Banner page={'contact'} />
            <section className='contact'>
                <div className="container default-padding">
                    <article className='mb-4'>
                        <h3 className='text-uppercase fw-bold'>{t(`contact.title`)}</h3>
                        <p>{t(`contact.sub`)}</p>
                    </article>
                    <div className="row mb-5 pb-5">
                        <div className="col-lg-8 col-md-12">
                            {errorMessage.length > 0 && <div className="alert alert-danger" role="alert">
                                <ol className='mb-0'>
                                    {errorMessage?.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ol>
                            </div>}
                            {successMessage && <div className="alert alert-success" role="alert">
                                {successMessage}
                            </div>}
                            <form ref={form} onSubmit={sendEmail} className='mb-3'>
                                <div className="mb-3 d-flex justify-between gap-3">
                                    <input type="text" name="last_name" className='form_input' placeholder='Nom *' ref={fnameRef} />
                                    <input type="text" name="first_name" className='form_input' placeholder='Prénom *' ref={lnameRef} />
                                </div>
                                <div className="mb-3 d-flex justify-between gap-3">
                                    <input type="email" name="email" className='form_input' placeholder='E-mail *' ref={emailRef} />
                                    <input type="text" name="telephone" className='form_input' placeholder='Téléphone *' ref={phoneRef} />
                                </div>
                                <div className="mb-3">
                                    <input type="text" name="subject" className='form_input' placeholder='Subject *' ref={subjectRef} />
                                </div>
                                <textarea name="details" placeholder='Details *' id="details" rows={4} cols={4} ref={messageRef}></textarea>
                                <button className='btn'>
                                    <span>Envoyer</span>
                                </button>
                            </form>

                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d849.2611404843054!2d-8.015098863329026!3d31.632630254370113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdafef406e46d83f%3A0xd9e4220091d2adcb!2sBIGWALL%20TECHNOLOGY!5e0!3m2!1sen!2sma!4v1715594985015!5m2!1sen!2sma"
                                width={"100%"}
                                height={"445px"}
                                allowFullScreen=""
                                loading={"lazy"} referrerPolicy="no-referrer-when-downgrade" title={'google map'}></iframe>

                        </div>

                        <div className="col-lg-4 col-md-12">
                            {/* <div className="google mb-4">
                                <div className="google-text">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faGoogle} fontSize={30} color={'#212c4f'} />
                                    </div>
                                    <div className="stars mb-2">
                                        <img loading="lazy" src={stars} alt="stars" />
                                    </div>
                                    <p>Evaluations Google : Basé sur <b>119</b> avis</p>
                                    <Link
                                        target={"_blank"}
                                        to={"https://www.google.com/maps/place/BIGWALL+TECHNOLOGY/@31.632624,-8.0147951,15z/data=!4m8!3m7!1s0xdafef406e46d83f:0xd9e4220091d2adcb!8m2!3d31.632624!4d-8.0147951!9m1!1b1!16s%2Fg%2F11rtd7khjm?entry=ttu"}>
                                        <span className="btn">
                                            Consulter les avis Google
                                            <FontAwesomeIcon className='ps-2' icon={faArrowRight} />
                                        </span>
                                    </Link>

                                </div>
                            </div> */}

                            <div className="linktags">
                                <div className="linktags-text">
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faTag} fontSize={30} color={'#212c4f'} />
                                    </div>
                                    <p>Les tags et hashtags les plus utilisés sur le domaine développement</p>
                                    <span className="tags-item">Création</span>
                                    <span className="tags-item">Site</span>
                                    <span className="tags-item">Internet</span>
                                    <span className="tags-item">SEO</span>
                                    <span className="tags-item">Référencement</span>
                                    <span className="tags-item">Stratégie</span>
                                    <span className="tags-item">Web</span>
                                    <span className="tags-item">Mot-clé</span>
                                    <span className="tags-item">Optimisation</span>
                                    <span className="tags-item">Suivi</span>
                                    <span className="tags-item">Web Offshore</span>
                                    <span className="tags-item">HTML5</span>
                                    <span className="tags-item">CSS3</span>
                                    <span className="tags-item">Php</span>
                                    <span className="tags-item">Python</span>
                                    <span className="tags-item">Prestashop</span>
                                    <span className="tags-item">CMS</span>
                                    <span className="tags-item">Dynamique</span>
                                    <span className="tags-item">Hosting</span>
                                    <span className="tags-item">Collaboration</span>
                                    <span className="tags-item">Accompagnement</span>
                                    <span className="tags-item">Rédaction (SEO)</span>
                                    <span className="tags-item">Publicité</span>
                                    <span className="tags-item">Webmarketing</span>
                                    <span className="tags-item">Adwords</span>
                                    <span className="tags-item">Javascript</span>
                                    <span className="tags-item">Plugins</span>
                                    <span className="tags-item">Wordpress</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
