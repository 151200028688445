import React from "react";
import circle_absolute from "../assets/images/services/circle.svg";
import { Link } from "react-router-dom";
import services from "../data/services";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { i18n, t } = useTranslation();

  return (
    <div id="services" className="services default-padding">
      <div className="container">
        <article className="mb-5">
          <h3 className="text-capitalize text-center" data-aos="zoom-in-up">
            {t(`services.title.normal`)} <span>{t(`services.title.span`)}</span>
          </h3>
          <p className="text-break text-center" data-aos="zoom-in-up">
            {t(`services.sub`)}
          </p>
        </article>
        <div className="row">
          {services.map((service, index) => (
            <div className="col-lg-3 col-md-12 mb-3" key={index} data-aos="zoom-out-left">
              <div className="card h-100 d-flex flex-column justify-content-between">
                <figure className="mb-4">
                  <img className="card-img-top" src={service.icon} alt="" />
                </figure>
                <div className="card-body p-0">
                  <h5 className="card-title mb-3">{i18n.language === "en" ? service.titleEN : service.titleFR}</h5>
                  <p className="card-text">{i18n.language === "en" ? service.descriptionEN : service.descriptionFR}</p>
                </div>
                {service.link && (
                  <div className="pl-3">
                    <Link
                      to={service.link}
                      className="d-flex align-items-center gap-2 p-0 text-decoration-none"
                      style={{
                        color: "#F47130",
                        background: "#fff",
                      }}
                      target={service.link.startsWith("http") ? "_blank" : ""}
                    >
                      <span>Explore</span>
                      <svg
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.2513 1.46323C11.9165 1.1285 11.9165 0.585786 12.2513 0.251051C12.586 -0.0836838 13.1287 -0.0836838 13.4634 0.251051L18.6063 5.39391C18.941 5.72864 18.941 6.27136 18.6063 6.60609L13.4634 11.7489C13.1287 12.0837 12.586 12.0837 12.2513 11.7489C11.9165 11.4142 11.9165 10.8715 12.2513 10.5368L15.9309 6.85713H0.866596C0.387988 6.85713 0 6.47337 0 5.99999C0 5.5266 0.387988 5.14284 0.866596 5.14284H15.9309L12.2513 1.46323Z"
                          fill="#FFCD00"
                        />
                      </svg>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <img src={circle_absolute} className="circle_absolute" alt="" />
      </div>
    </div>
  );
};

export default Services;
