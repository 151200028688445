import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Sidebar from "./components/common/Sidebar";
import Preloader from "./components/common/Preloader";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Applications from "./pages/Applications";
import Contact from "./pages/Contact";

// Import I18n
import "./i18n/i18n";
import { useTranslation } from "react-i18next";

// Scroll Animation
import AOS from 'aos';
import 'aos/dist/aos.css';
import Privacy from "./pages/Privicy";
import Terms from "./pages/Terms";

import ScrollToTop from './components/common/ScrollToTop.jsx'; // Import ScrollToTop component

const App = () => {
  const { i18n } = useTranslation();
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPreloader(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  document.dir = i18n.dir();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <Router>
      <Suspense fallback={<Preloader />}>
        {showPreloader && <Preloader />}
        <ScrollToTop />
        <Header />
        <Navbar />
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
};

export default App;
