import client1 from "../assets/images/clients/client1.webp";
import client2 from "../assets/images/clients/client2.webp";
import client3 from "../assets/images/clients/client3.webp";

const reviews = [
  {
    name: "Mati Mustafi",
    descriptionEN: `Kudos to the team for their work. 
      They are true professionals in mobile applications.
      Their qualities:
      • Seriousness 
      • Reactivity 
      • Quality deliverables
      • Constant support.
      I highly recommend them, Thanks to Anas and Rachid for their work.`,
    descriptionFR: `Bravo à l'équipe pour leur travail. 
      Ce sont de vrais professionnels des applications mobiles.
      Leurs qualités :
      • Le sérieux 
      • La réactivité 
      • Livrable de qualité
      • Accompagnement constant.
      Je les recommande fortement, Merci à Anas et Rachid pour leur travail.`,
    src: client3,
    roleEN: "Ramsys Maroc",
    roleFR: "Ramsys Maroc",
  },
  {
    name: "Hiba",
    descriptionEN: `I worked with BIGWALL TECHNOLOGY on a stock management application, I appreciated the attention to detail and their great concern for my issues. They demonstrated availability and reliability throughout the project, I am very satisfied.`,
    descriptionFR: `J'ai travaillé avec BIGWALL TECHNOLOGY dans le cadre d'une application de gestion de stock, j'ai apprécié le souci du détail et leur grande attention envers ma problématique. Ils ont fait preuve de disponibilité et de fiabilité tout au long du projet, je suis très satisfaite.`,
    src: client1,
    roleEN: "Founder of H kids",
    roleFR: "Fondatrice de H kids",
  },
  {
    name: "Hôtel & Ryads Barrière Le Naoura",
    descriptionEN: `A professional team that listens. I loved working with them, and it won't be the last time.`,
    descriptionFR: `Une équipe professionnelle et à l'écoute. J'ai adoré travailler avec eux, et ça ne sera pas la dernière fois.`,
    src: client2,
    roleEN: "Hotel",
    roleFR: "Hôtel",
  },
];

export default reviews;
