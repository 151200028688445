import React from "react";
import logo from "../../assets/images/logo/logo.webp";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelopeOpen,
  faLocation,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
  // const pages = ["Home", "Portfolio", "Products", "Contact"];

  return (
    <>
      <footer className="footer-section">
        <div className="footer-cta pt-5 pb-5">
          <div className="container">
            <div className="row gap-3">
              <div className="col-lg-4 mx-auto">

                <div className="single-cta">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLocation} />
                  </div>
                  <div className="cta-text">
                    <h4>Address</h4>
                    <span>
                      N 5, res Firdaous, Bd Moulay Rachid, Marrakech 40000
                    </span>
                  </div>
                </div>

              </div>
              <div className="col-lg-3 mx-auto">

                <div className="single-cta">
                  <div className="icon">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <div className="cta-text">
                    <h4>Phone</h4>
                    <span>08086-01751</span>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 mx-auto">

                <div className="single-cta">
                  <div className="icon">
                    <FontAwesomeIcon icon={faEnvelopeOpen} />
                  </div>
                  <div className="cta-text">
                    <h4>Email</h4>
                    <span>contact@bigwalltechnology.com</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to="/">
                      <img
                        src={logo}
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                      <strong>Bigwall Technology</strong> is a dynamic agency based in Marrakech, Morocco, leading the charge in digital
                      transformation and web development since its establishment in 2020. Despite its relatively recent entry
                      into the market, Bigwall has swiftly garnered acclaim for its prowess in these domains. With a dedicated
                      team of seasoned professionals adept in web development, mobile application development,
                      and digital marketing, the company champions innovation and remains at the forefront of technological evolution.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <div className="footer-social-links mb-5">

                      <Link to={"https://www.facebook.com/Bigwalltechnology/"} target="_blank">
                        <div className="icon">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </div>
                      </Link>

                      <Link to={"https://ma.linkedin.com/company/bigwall-technology"} target="_blank">
                        <div className="icon">
                          <FontAwesomeIcon icon={faXTwitter} />
                        </div>
                      </Link>

                      <Link to={"https://www.instagram.com/bigwalltechnology/"} target="_blank">
                        <div className="icon">
                          <FontAwesomeIcon icon={faInstagram} />
                        </div>
                      </Link>

                    </div>
                  </div>
                </div>
              </div>

              <div className="ps-sp col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Services</h3>
                  </div>

                  <ul>

                    <li className="mb-3">
                      Creation des siteweb
                    </li>

                    <li className="mb-3">
                      Création Des Applications Mobile
                    </li>

                    <li className="mb-3">
                      Conception Graphique 2D/3D
                    </li>

                    <li className="mb-3">
                      Vente/Maintenance Du Matériel Informatique
                    </li>

                  </ul>


                </div>
              </div>

              <div className=" col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>

                    <li className="mb-3">
                      <Link to='/privacy'>
                        Privicy Policy
                      </Link>
                    </li>
                    <li className="mb-3">
                      <Link to="/terms">
                        Terms of use
                      </Link>
                    </li>

                  </ul>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright &copy; 2020 - 2024 All Right Reserved
                    <Link to={"https://bigwalltechnology.com/"} target="_blank" className="ps-2">
                      BIGWALL TECHNOLOGY
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
