import game1 from "../assets/images/studio/1.png";
import game2 from "../assets/images/studio/2.png";
import game3 from "../assets/images/studio/3.png";
import game4 from "../assets/images/studio/4.png";
import game5 from "../assets/images/studio/5.webp";

const games = [
    {
        title: "Bricks Ball Builder",
        link: "https://play.google.com/store/apps/details?id=com.bigwall.bricksballbuilder",
        src: game5
    },
    {
        title: "Brick Climb Battle",
        link: "https://play.google.com/store/apps/details?id=com.bigwallstudio.brickclimbbattle",
        src: game1
    },
    {
        title: "Scooter Factory",
        link: "https://play.google.com/store/apps/details?id=com.bigwall.scooterfactory&gl=US",
        src: game2
    },
    {
        title: "Simply Juice!",
        link: "https://play.google.com/store/apps/details?id=com.bigwall.simplyjuice",
        src: game3
    },
]

export default games;