import React from 'react';
import { Card } from 'react-bootstrap';

const Privacy = () => {
    return (
        <div className="container mt-5 mb-5">
            <Card>
                <Card.Body>
                    <Card.Title className="mb-4 fw-bold">Privacy Policy</Card.Title>
                    <Card.Text>
                        This privacy policy is applicable to the BIGWALL STUDIO app (hereinafter referred to as "Application") for mobile devices, which was developed by BIGWALL TECHNOLOGY (hereinafter referred to as "Service Provider") as a Free service. This service is provided "AS IS".
                    </Card.Text>

                    <Card.Title className="mt-4">What information does the Application obtain and how is it used?</Card.Title>
                    <Card.Text>
                        <strong>User Provided Information</strong><br />
                        The Application acquires the information you supply when you download and register the Application. Registration with the Service Provider is not mandatory. However, bear in mind that you might not be able to utilize some of the features offered by the Application unless you register with them.
                    </Card.Text>
                    <Card.Text>
                        The Service Provider may also use the information you provided them to contact you from time to time to provide you with important information, required notices, and marketing promotions.
                    </Card.Text>

                    <Card.Title className="mt-4">Automatically Collected Information</Card.Title>
                    <Card.Text>
                        In addition, the Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device's unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.
                    </Card.Text>

                    <Card.Title className="mt-4">Does the Application collect precise real-time location information of the device?</Card.Title>
                    <Card.Text>
                        This Application does not gather precise information about the location of your mobile device.
                    </Card.Text>

                    <Card.Title className="mt-4">Do third parties see and/or have access to information obtained by the Application?</Card.Title>
                    <Card.Text>
                        Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Application and their service. The Service Provider may share your information with third parties in the ways that are described in this privacy statement.
                    </Card.Text>
                    <Card.Text>
                        Please note that the Application utilizes third-party services that have their own Privacy Policy about handling data. Below are the links to the Privacy Policy of the third-party service providers used by the Application:
                    </Card.Text>
                    <ul>
                        <li>Google Play Services</li>
                        <li>Google Analytics for Firebase</li>
                        <li>Facebook</li>
                        <li>Unity</li>
                        <li>GameAnalytics</li>
                        <li>AppLovin</li>
                    </ul>

                    <Card.Title className="mt-4">What are my opt-out rights?</Card.Title>
                    <Card.Text>
                        You can halt all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
                    </Card.Text>

                    <Card.Title className="mt-4">Data Retention Policy, Managing Your Information</Card.Title>
                    <Card.Text>
                        The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. The Service Provider will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If you'd like the Service Provider to delete User Provided Data that you have provided via the Application, please contact them at <a href="mailto:contact@bigwalltechnology.com">contact@bigwalltechnology.com</a> and they will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.
                    </Card.Text>

                    <Card.Title className="mt-4">Children</Card.Title>
                    <Card.Text>
                        The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13. The Application does not address anyone under the age of 13. The Service Provider does not knowingly collect personally identifiable information from children under 13 years of age. In the case the Service Provider discover that a child under 13 has provided personal information, the Service Provider will immediately delete this from their servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact the Service Provider (<a href="mailto:contact@bigwalltechnology.com">contact@bigwalltechnology.com</a>) so that they will be able to take the necessary actions.
                    </Card.Text>

                    <Card.Title className="mt-4">Security</Card.Title>
                    <Card.Text>
                        The Service Provider is concerned about safeguarding the confidentiality of your information. They provide physical, electronic, and procedural safeguards to protect information they process and maintain. For example, they limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop, or improve their Application. Please be aware that, although they endeavor to provide reasonable security for information they process and maintain, no security system can prevent all potential security breaches.
                    </Card.Text>

                    <Card.Title className="mt-4">Changes</Card.Title>
                    <Card.Text>
                        This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
                    </Card.Text>

                    <Card.Text>
                        This privacy policy is effective as of 2024-07-01.
                    </Card.Text>

                    <Card.Title className="mt-4">Your Consent</Card.Title>
                    <Card.Text>
                        By using the Application, you are giving your consent to the Service Provider processing of your information as set forth in this Privacy Policy now and as amended by us. "Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining, and disclosing information.
                    </Card.Text>

                    <Card.Title className="mt-4">Contact Us</Card.Title>
                    <Card.Text>
                        If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at <a href="mailto:contact@bigwalltechnology.com">contact@bigwalltechnology.com</a>.
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Privacy;
