import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Statistics = () => {
  const { i18n, t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const [satisfaction, setSatisfaction] = useState(0);
  const [clients, setClients] = useState(0);
  const [formations, setFormations] = useState(0);
  const [sites, setSites] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const statisticsSection = document.querySelector(".statistics");
      if (!isVisible && statisticsSection.getBoundingClientRect().top < window.innerHeight) {
        setIsVisible(true);
        const interval = setInterval(() => {
          // Increment the numbers until they reach their target values
          setSatisfaction(prevState => prevState < 99 ? prevState + 1 : 99);
          setClients(prevState => prevState < 189 ? prevState + 1 : 189);
          setFormations(prevState => prevState < 376 ? prevState + 1 : 376);
          setSites(prevState => prevState < 201 ? prevState + 1 : 201);
        }, 25); // Interval in milliseconds, adjust as needed
        // Cleanup function to clear the interval when component unmounts or when the section is out of view
        return () => clearInterval(interval);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isVisible]);

  return (
    <div className="statistics">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="card border-0">
              <div className="card-body text-center" data-aos="zoom-out">
                <p className="card-number mb-0">{satisfaction}%</p>
                <h4 className="card-title">{t(`statistics.satisfaction`)}</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="card border-0">
              <div className="card-body text-center" data-aos="zoom-out">
                <p className="card-number mb-0">{clients}+</p>
                <h4 className="card-title">{t(`statistics.clients`)}</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="card border-0">
              <div className="card-body text-center" data-aos="zoom-out">
                <p className="card-number mb-0">{formations}+</p>
                <h4 className="card-title">{t(`statistics.formations`)}</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="card border-0">
              <div className="card-body text-center" data-aos="zoom-out">
                <p className="card-number mb-0">+{sites}</p>
                <h4 className="card-title">{t(`statistics.sites`)}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
