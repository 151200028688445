import React from 'react';
import { Card } from 'react-bootstrap';

const Terms = () => {
    return (
        <div className="container mt-5 mb-5">
            <Card>
                <Card.Body>
                    <Card.Title className="mb-4 fw-bold">Terms & Conditions</Card.Title>
                    <Card.Text>
                        These terms and conditions apply to the BIGWALL STUDIO app (hereby referred to as "Application") for mobile devices that was created by BIGWALL TECHNOLOGY (hereby referred to as "Service Provider") as a Free service.
                    </Card.Text>

                    <Card.Title className="mt-4">Agreement to Terms</Card.Title>
                    <Card.Text>
                        Upon downloading or utilizing the Application, you are automatically agreeing to the following terms. It is strongly advised that you thoroughly read and understand these terms prior to using the Application.
                    </Card.Text>
                    <Card.Text>
                        Unauthorized copying, modification of the Application, any part of the Application, or our trademarks is strictly prohibited. Any attempts to extract the source code of the Application, translate the Application into other languages, or create derivative versions are not permitted. All trademarks, copyrights, database rights, and other intellectual property rights related to the Application remain the property of the Service Provider.
                    </Card.Text>

                    <Card.Title className="mt-4">Modifications</Card.Title>
                    <Card.Text>
                        The Service Provider reserves the right to modify the Application or charge for their services at any time and for any reason. Any charges for the Application or its services will be clearly communicated to you.
                    </Card.Text>

                    <Card.Title className="mt-4">Data Handling</Card.Title>
                    <Card.Text>
                        The Application stores and processes personal data that you have provided to the Service Provider in order to provide the Service. It is your responsibility to maintain the security of your phone and access to the Application.
                    </Card.Text>
                    <Card.Text>
                        The Service Provider advises against jailbreaking or rooting your phone, which could compromise your phone's security features and affect the functionality of the Application.
                    </Card.Text>

                    <Card.Title className="mt-4">Third-Party Services</Card.Title>
                    <Card.Text>
                        The Application utilizes third-party services that have their own Terms and Conditions. Below are the links to the Terms and Conditions of the third-party service providers used by the Application:
                    </Card.Text>
                    <ul>
                        <li><a href="https://play.google.com/intl/en_us/about/play-terms.html">Google Play Services</a></li>
                        <li><a href="https://firebase.google.com/terms/google-analytics">Google Analytics for Firebase</a></li>
                        <li><a href="https://www.facebook.com/terms.php">Facebook</a></li>
                        <li><a href="https://unity3d.com/legal/terms-of-service">Unity</a></li>
                        <li><a href="https://gameanalytics.com/terms/">GameAnalytics</a></li>
                        <li><a href="https://www.applovin.com/terms/">AppLovin</a></li>
                    </ul>

                    <Card.Title className="mt-4">Limitations of Liability</Card.Title>
                    <Card.Text>
                        The Service Provider does not assume responsibility for certain aspects, such as interruptions in service due to internet connectivity issues or charges incurred from mobile network providers.
                    </Card.Text>
                    <Card.Text>
                        It is your responsibility to ensure your device remains charged and operational for accessing the Application.
                    </Card.Text>

                    <Card.Title className="mt-4">Updates and Termination</Card.Title>
                    <Card.Text>
                        The Service Provider may update the Application and terminate its use at any time without notice. It is your responsibility to accept updates to the Application to continue using it.
                    </Card.Text>

                    <Card.Title className="mt-4">Changes to These Terms and Conditions</Card.Title>
                    <Card.Text>
                        The Service Provider may periodically update these Terms and Conditions. You are advised to review this page regularly for any changes.
                    </Card.Text>

                    <Card.Text>
                        These terms and conditions are effective as of 2024-07-01.
                    </Card.Text>

                    <Card.Title className="mt-4">Contact Us</Card.Title>
                    <Card.Text>
                        If you have any questions or suggestions about the Terms and Conditions, please contact the Service Provider at <a href="mailto:contact@bigwalltechnology.com">contact@bigwalltechnology.com</a>.
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Terms;
