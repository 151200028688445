import company1 from "../assets/images/clients/tapestry.png";
import company2 from "../assets/images/clients/itpro.png";
import company3 from "../assets/images/clients/kids.png";
import company4 from "../assets/images/clients/voip.png";

const companies = [
  {
    src: company1,
    link: "#"
  },
  {
    src: company2
  },
  {
    src: company3
  },
  {
    src: company4,
    link: "#"
  },
];

export default companies;
