import graphique from "../assets/images/services/graphique.svg";
import maintenance from "../assets/images/services/maintenance.svg";
import mobile from "../assets/images/services/mobile.svg";
import siteweb from "../assets/images/services/siteweb.svg";

const services = [
  {
    titleFR: "Création des applications Mobile",
    titleEN: "Mobile Application Development",
    descriptionFR:
      "Nous proposons une solution mobile sur-mesure, et multi-plateformes sur Android et IOS. Notre équipe de développeurs se chargera de développer des applications mobiles à votre goût, afin d'obtenir le maximum d'impact en termes d'utilisateurs et de déploiement. Nous vous garantissons, rapidité, fluidité et un suivi des mises à jour des applications.",
    descriptionEN:
      "We offer a customized mobile solution, multi-platform on Android and IOS. Our team of developers will develop mobile applications to your taste, in order to obtain the maximum impact in terms of users and deployment. We guarantee you, speed, fluidity, and follow-up of application updates.",
    icon: mobile,
    link: "https://play.google.com/store/apps/dev?id=7552391971648853116"
  },
  {
    titleFR: "Conception graphique 2D/3D",
    titleEN: "2D/3D Graphic Design",
    descriptionFR:
      "Nous fournissons des services de conception graphique de haute qualité pour les projets 2D et 3D. Nos designers experts créent des visuels époustouflants et des animations captivantes pour répondre à vos besoins spécifiques, qu'il s'agisse de marketing, de branding ou de supports interactifs.",
    descriptionEN:
      "We provide high-quality graphic design services for 2D and 3D projects. Our expert designers create stunning visuals and captivating animations to meet your specific needs, whether it's for marketing, branding, or interactive media.",
    icon: graphique,
    link: "https://www.artstation.com/bigwall"
  },
  {
    titleFR: "Création de site web",
    titleEN: "Web Development",
    descriptionFR:
      "Nos experts du web se chargerons de la conception d'un site web adapté à vos besoins. Nous comprenons que la création d'un site web peut impacter positivement le taux de conversion de vos ventes. Sur ce nous nous engageons à vous produire un site web à haut rendement avec un référencement SEO approprié à vos objectifs commerciaux.",
    descriptionEN:
      "Our web experts will handle the design of a website tailored to your needs. We understand that creating a website can positively impact your sales conversion rate. Therefore, we are committed to delivering a high-performance website with SEO tailored to your business goals.",
    icon: siteweb,
    link: "/applications"
  },
  {
    titleFR: "Vente/maintenance du matériel informatique",
    titleEN: "Sale/Maintenance of IT Equipment",
    descriptionFR:
      "Nous offrons des services complets de vente et de maintenance de matériel informatique. Que vous ayez besoin de nouveaux équipements ou de réparations pour votre matériel existant, notre équipe expérimentée est là pour vous fournir des solutions rapides et efficaces pour garantir la continuité de vos opérations.",
    descriptionEN:
      "We offer comprehensive sales and maintenance services for IT equipment. Whether you need new equipment or repairs for your existing hardware, our experienced team is here to provide you with quick and efficient solutions to ensure the continuity of your operations.",
    icon: maintenance,
    // I removed that link
  },
];

export default services;
