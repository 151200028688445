import box from '../assets/images/apps/box.png';
import app from '../assets/images/apps/apps.png';
import webwiz from '../assets/images/apps/webwiz.png';

const apps = [
    {
        titleEN: {
            normal: "Management",
            span: "Application",
        },
        titleFR: {
            normal: "Application De",
            span: "Gestion",
        },
        descEN: "Cloud-based commercial management software, available on Mac/Windows, simplifies the daily management of your business with just a few clicks. The application integrates various steps of commercial management (Clients, Suppliers, management of invoices and quotes, management of credits and advances, payment management, customer reminders as well as purchase management, inventory, etc.).",
        descFR: "Logiciel de gestion commerciale Cloud, disponible sur Mac/Windows, simplifie la gestion quotidienne de votre entreprise en quelques clics. L'application intègre différentes étapes de la gestion commerciale (clients, fournisseurs, gestion des factures et des devis, gestion des avoirs et des acomptes, gestion des paiements, rappels clients ainsi que gestion des achats, inventaire, etc.).",
        src: box
    },

    {
        titleEN: {
            normal: "Mobile",
            span: "Applications",
        },
        titleFR: {
            normal: "Applications",
            span: "Mobiles",
        },
        descEN: "Mobile applications are all the rage these days and most businesses are capitalizing on this trend to venture into exciting new markets. Our agency has vast experience in creating native applications for IOS, Windows, and Android platforms.",
        descFR: "Les applications mobiles font fureur ces jours-ci et la plupart des entreprises profitent de cette tendance pour se lancer dans de nouveaux marchés passionnants. Notre agence possède une vaste expérience dans la création d'applications natives pour les plates-formes mobiles IOS, Windows et Android.",
        src: app
    },

    {
        titleEN: {
            normal: "WebWiz All-in-One",
            span: "Web Management Platform with Node.js and React",
        },
        titleFR: {
            normal: "WebWiz All-in-One",
            span: "Plateforme de gestion Web avec Node.js et React",
        },
        descEN: "Web applications are all the rage these days and most businesses are capitalizing on this trend to venture into exciting new markets. Our agency has vast experience in creating native applications for IOS, Windows, and Android platforms.",
        descFR: "Les applications Web font fureur ces jours-ci et la plupart des entreprises profitent de cette tendance pour se lancer dans de nouveaux marchés passionnants. Notre agence possède une vaste expérience dans la création d'applications natives pour les plates-formes mobiles IOS, Windows et Android.",
        src: webwiz
    },
]

export default apps;
