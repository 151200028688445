import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Header = () => {
  return (
    <div className="header">
      <div className="container">
        <div>
          {/* LOCATION */}
          <div className="d-flex align-items-center">
            <FontAwesomeIcon fontSize={14} color="#fff" icon={faLocationDot} />
            <span className="ps-2 text-white ">N 5, res Firdaous, Bd Moulay Rachid, Marrakech 40000</span>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          {/* START PHONE */}
          <div className="d-flex align-items-center">
            <FontAwesomeIcon fontSize={14} color="#fff" icon={faPhone} />
            <span className="ps-2 text-white ">08086-01751</span>
          </div>


          {/* START MAIL */}
          <div className="d-flex align-items-center">
            <FontAwesomeIcon fontSize={14} color="#fff" icon={faEnvelope} />
            <span className="ps-2 text-white ">contact@bigwalltechnology.com</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
